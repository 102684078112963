var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Criar Usuario "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"indigo darken-1","dark":"","fab":"","small":"","to":{name: 'user.read'}}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1)],1),_c('v-card-text',[_c('v-alert',{attrs:{"dismissible":"","color":_vm.alert.type,"border":"left","elevation":"2","colored-border":"","transition":"scale-transition"},model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c('v-alert',{attrs:{"dismissible":"","color":_vm.alert.type,"border":"left","elevation":"2","colored-border":"","transition":"scale-transition"},model:{value:(_vm.alertSuccess.show),callback:function ($$v) {_vm.$set(_vm.alertSuccess, "show", $$v)},expression:"alertSuccess.show"}},[_vm._v(" "+_vm._s(_vm.alertSuccess.text)+" ")]),_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Nome","counter":"","maxlength":"16","rules":[
                                _vm.form_login_rules.required, _vm.form_login_rules.counter,
                                    _vm.form_login_rules.min
                            ]},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Senha","maxlength":"16","hint":"Pelo menos 8 caracteres","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                                _vm.form_senha_rules.required, _vm.form_senha_rules.min
                            ]},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.form.senha),callback:function ($$v) {_vm.$set(_vm.form, "senha", $$v)},expression:"form.senha"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"Email de Recuperação","maxlength":"64","rules":[
                                _vm.form_email_rules.required, _vm.form_email_rules.counter,
                                    _vm.form_email_rules.email
                            ]},model:{value:(_vm.form.email_recuperacao),callback:function ($$v) {_vm.$set(_vm.form, "email_recuperacao", $$v)},expression:"form.email_recuperacao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"label":"Tipo de Usuario","items":_vm.sectionsUser,"item-text":"descricao","item-value":"id","rules":[_vm.form_usuario_rules.required]},model:{value:(_vm.form.id_tipo_usuario),callback:function ($$v) {_vm.$set(_vm.form, "id_tipo_usuario", $$v)},expression:"form.id_tipo_usuario"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"label":"Nome do Registro","items":_vm.sectionsPeople,"item-text":"descricao","item-value":"id","rules":[_vm.form_pessoa_rules.required]},model:{value:(_vm.form.id_pessoa),callback:function ($$v) {_vm.$set(_vm.form, "id_pessoa", $$v)},expression:"form.id_pessoa"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"indigo darken-1","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-icon',[_vm._v(" mdi-save ")]),_vm._v(" Salvar ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"pink darken-1","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.returnRead()}}},[_c('v-icon',[_vm._v(" mdi-block ")]),_vm._v(" Cancelar ")],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":"100","color":"indigo darken-1"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }